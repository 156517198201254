export default defineNuxtRouteMiddleware((to) => {
  if (to.path === "/auth/logout/success" && to.query.error !== undefined) {
    return navigateTo({
      path: "/auth/error",
      query: {
        error: to.query.error,
      },
      replace: true,
    });
  }
});
